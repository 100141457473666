var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-side-bar"
  }, [_c('div', {
    staticClass: "app-side-bar__menu"
  }, [_c('GenproxMenu', {
    attrs: {
      "menus": _vm.filteredMenus
    }
  }), _c('p', {
    staticClass: "app-side-bar__label"
  }, [_vm._v("SUPPORT")]), _c('GenproxMenu', {
    attrs: {
      "menus": _vm.supportMenus
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }