
















import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import UserPresentation from './UserPresentation.vue';

@Component({
  components: { UserPresentation }
})
export default class GenproxDropdownItem extends Vue {
  @Prop() image!: String;
  @Prop() label!: String;
}
