














import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop, Watch} from "vue-property-decorator";
import GenproxDropdownItem from './GenproxDropdownItem.vue';
import { MenuItem } from '@/modules/genprox/store/types';
import { mapState } from 'vuex';

@Component({
  components: { GenproxDropdownItem },
  computed: {
    ...mapState("genprox", {
      menus: (state: any) => state.menu,
    })
  }
})
export default class GenproxProfileMenu extends Vue {
  @Prop() image!: String;
  @Prop() label!: String;
  
  isOpened: boolean = false;
  reRender: number = 0;
  menus!: MenuItem[];

  get profilePage(): MenuItem {
    return this.menus?.find((item: MenuItem) => item?.label === 'My profile')
  }

  get welcomePage(): MenuItem {
    return this.menus?.find((item: MenuItem) => item?.label === 'Welcome')
  }

  openDropdown() {
    this.isOpened = true
  }

  closeDropdown() {
    this.isOpened = false
  }

  toggleDropdown() {
    this.isOpened = !this.isOpened
  }

  logout(): void {
    this.$store.dispatch('auth/logout');
  }

  @Watch('menus', { deep: true }) onMenusChange() {
    this.reRender++
  }
}
