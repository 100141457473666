

















import Vue from "vue";
import Component from "vue-class-component";
import SygniMenu from "@/components/sygni-menu/SygniMenu.vue";
import GenproxMenu from "@/components/sygni-menu/GenproxMenu.vue";
import { MenuItem } from "@/modules/genprox/store/types";
import { mapState } from "vuex";
import { Watch } from "vue-property-decorator";
import { Contexts } from "../models/User";
import { Package } from "../models/Package";

@Component({
  components: { SygniMenu, GenproxMenu },
  computed: {
    ...mapState("genprox", {
      menus: (state: any) => state.menu,
      packages: (state: any) => state.packages,
    }),
  },
})
export default class AppSideBar extends Vue {
  menus!: MenuItem[];
  packages!: Array<Package>;
  activeMenu: MenuItem | null = null;

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get freeToolsAvailable() {
    return this.activeUserData?.context?.context === Contexts.fundManager || this.activeUserData?.context?.context === Contexts.fundManagerPrivate || this.activeUserData?.context?.context === Contexts.fund
  }

  get filteredMenus(): MenuItem[] {
    return this.menus?.filter((el: MenuItem) => !(el?.label === 'Insights' || el?.label === 'My profile'))
  }

  get supportMenus(): MenuItem[] {
    const supportMenuItems: MenuItem[] = [
      { label: "Insights", route: "/insights", children: [], isActive: false },
    ]

    if (this.packages?.length) {
      supportMenuItems.push({ label: "Plans", route: "/plans", children: [], isActive: false })
    }

    if (this.freeToolsAvailable) {
      supportMenuItems.push({ label: "Tools", route: "/tools", children: [], isActive: false })
    }

    return supportMenuItems
  }

  mounted() {
    this.getMenus();
  }

  async getMenus() {
    this.setActiveMenu();
  }

  onActiveMenuChange(activeMenu: MenuItem): void {
    if (this.$route.path !== activeMenu.route) {
      if (activeMenu.route.includes("https")) {
        this.$router.push({
          name: "redirect",
          params: { route: activeMenu.route },
        });
      } else {
        this.$router.push({ path: activeMenu.route?.toLowerCase() });
      }
    }
  }

  @Watch("$route")
  setActiveMenu(): void {
    let found = false;
    this.menus.forEach((menu: MenuItem) => {
      if (this.$route.path.includes(menu.route?.toLowerCase())) {
        this.activeMenu = menu;
        return;
      }

      if (menu.children?.length > 0) {
        menu.children?.forEach((menuItem: MenuItem) => {
          if (
            this.$route.path.includes(menuItem.route?.toLowerCase()) ||
            this.$route.path.includes("/investor/portfolio/by-company")
          ) {
            if (
              this.$route.path.includes("/investor/portfolio") &&
              menuItem.route == "/investor/portfolio/by-fund"
            ) {
              this.activeMenu = menuItem;
              found = true;
            }

            if (!found) {
              this.activeMenu = menuItem;
              found = true;
            }
            return;
          }
        });
      }
    });
  }
}
