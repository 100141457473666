




































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import GenproxDropdownItem from '../GenproxDropdownItem.vue';
import { Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { UserContext } from '@/modules/genprox/models/User';
import { SelectOption } from '@/store/types';
import PlusCircleIcon from '@/assets/icons/heroicons/PlusCircleIcon.vue';
import simplebar from 'simplebar-vue';
import _ from 'lodash';
import 'simplebar/dist/simplebar.min.css';

@Component({
  components: { SygniCheckbox, SygniInputError, SygniRoundedButton, SygniRectButton, GenproxDropdownItem, PlusCircleIcon, simplebar },
  computed: {
    ...mapState('genprox', {
      userContexts: (state: any) => state.userContexts,
      activeUserContextId: (state: any) => state.activeUserData.context?.id,
      isVerifiedProfile: (state: any) => state.activeUserData?.verified,
      user: (state: any) => state.activeUserData?.user,
    }),
  }
})
export default class GenproxLeSelect extends Vue {
  @Prop() options!: SelectOption[];
  @Prop({default: 'label'}) optionsLabel!: string;
  @Prop({default: false}) hasAfterlist: boolean;
  @Prop({default: null}) image: any;
  @Prop({default: ''}) label: string;

  userContexts!: UserContext[];
  activeUserContextId!: string;
  isVerifiedProfile!: boolean;
  searchPhrase: string = '';
  isOpened: boolean = false;
  user: any;

  openDropdown() {
    this.searchPhrase = ''
    this.isOpened = true
    this.setFocus()
  }

  toggleDropdown() {
    this.searchPhrase = ''
    this.isOpened = !this.isOpened
    this.setFocus()
  }

  closeDropdown() {
    this.isOpened = false
    this.searchPhrase = ''
  }

  setFocus() {
    this.$nextTick(() => {
      (this.$refs?.gnLeSelectInput as HTMLInputElement)?.focus()
    })
  }

  get formattedOptions(): any[] {
    const optionKey: string = this.optionsLabel ?? 'label';
    if (this.options && this.options[0] && this.options[0][optionKey]) {
      this.options.forEach(option => {
        if(option[optionKey]) {
          option[optionKey][0].toUpperCase() + option[optionKey].slice(1, option[optionKey].length)
        }
      });
      return this.options;
    } else {
      return []
    }
  }

  get contextOptions() {
    const options = _.cloneDeep(this.formattedOptions)?.filter((el: any) => el?.label?.toUpperCase()?.includes(this.searchPhrase?.toUpperCase()))?.sort((a: any, b: any) => {
      if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
        return -1;
      }
      if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const personalAccount = options.find((el: any) => el?.label === 'Personal Account');

    if (personalAccount) {
      options.unshift(options.splice(options.indexOf(personalAccount), 1)[0]);
    }

    return options;
  }

  get formattedContextOptions() {
    return _.cloneDeep(this.contextOptions)?.sort((a: any) => {
      if (a?.label?.toUpperCase() === 'PERSONAL ACCOUNT') {
        return -9999;
      }

      if (a?.value === this.activeUserContextId) {
        return -9998;
      }

      return 0
    })
  }

  get userDescription() {
    return !this.isVerifiedProfile && !this.user?.professionalHeader ? 'Update your profile' : this.user.professionalHeader
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get personalAccount() {
    const contexts = this.$store.getters['genprox/userContexts']

    return contexts?.find((el: any) => el?.name?.toLowerCase() === 'personal account')
  }

  get isPersonalAccount() {
    return !!(this.activeUserData?.context?.name?.toLowerCase() === 'personal account')
  }

  highlightedText(option: string, data: any) {
    if (option && this.searchPhrase) {
      const index = option.toLowerCase().indexOf(this.searchPhrase?.toLowerCase());
      if (index >= 0) {
        option = option.substring(0, index) + "<span class='highlighted-text'>" + option.substring(index, index + this.searchPhrase?.length) + "</span>" + option.substring(index + this.searchPhrase?.length);
      }
    }

    if (data) {
      let additionalString = ` ${data.tin ? `| ${data.tin}` : ''}`;
      const index = additionalString.toLowerCase().indexOf(this.searchPhrase?.toLowerCase());
      if (index >= 0) {
        additionalString = additionalString.substring(0, index) + "<span class='highlighted-text'>" + additionalString.substring(index, index + this.searchPhrase?.length) + "</span>" + additionalString.substring(index + this.searchPhrase?.length);
      }
      option += `${additionalString}`;
    }

    return option;
  }

  handleInputEvent(option: any) {
    if (option?.value !== this.activeUserContextId) {
      this.$root.$emit('clearTablesData')
      this.$emit('input', option?.value);
      this.closeDropdown()
    }
  }

  addNewFundManagerHandle() {
    if (this.isPersonalAccount) {
      this.$emit('addNewFund')
    } else {
      if (this.personalAccount) {
        this.$root.$emit('setUserContext', this.personalAccount, '/fund/select-fund');
        (this.$refs.multiSelect as any).isOpen = false
      } else {
        this.$emit('addNewFund')
      }
    }
  }
}

