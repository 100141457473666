import { render, staticRenderFns } from "./GenproxLeSelect.vue?vue&type=template&id=0964f90b&scoped=true&"
import script from "./GenproxLeSelect.vue?vue&type=script&lang=ts&"
export * from "./GenproxLeSelect.vue?vue&type=script&lang=ts&"
import style0 from "./GenproxLeSelect.vue?vue&type=style&index=0&id=0964f90b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0964f90b",
  null
  
)

export default component.exports