var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-dropdown"
  }, [_c('div', {
    staticClass: "gn-dropdown__avatar"
  }, [_c('user-presentation', {
    attrs: {
      "image": _vm.image,
      "name": _vm.label
    }
  })], 1), _c('p', {
    staticClass: "gn-dropdown__value"
  }, [_vm._t("label", function () {
    return [_vm._v(" " + _vm._s(_vm.label) + " ")];
  })], 2), _c('div', {
    staticClass: "gn-dropdown__arrow"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('CARET DOWN'),
      "alt": "Toggle"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }