var render = function () {
  var _vm$formattedContextO;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    class: ['gn-le-select', _vm.isOpened ? 'gn-le-select--focus' : ''],
    on: {
      "click": _vm.openDropdown
    }
  }, [_c('div', {
    staticClass: "gn-le-select__value"
  }, [!_vm.isOpened ? _c('GenproxDropdownItem', {
    attrs: {
      "image": _vm.image,
      "label": _vm.label
    }
  }) : _c('div', {
    staticClass: "gn-le-select__input",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchPhrase,
      expression: "searchPhrase"
    }],
    ref: "gnLeSelectInput",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.searchPhrase
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchPhrase = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gn-le-select__arrow",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleDropdown.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('CARET DOWN'),
      "alt": "Toggle items"
    }
  })])], 1), _vm.isOpened ? _c('div', {
    staticClass: "gn-le-select__dropdown"
  }, [(_vm$formattedContextO = _vm.formattedContextOptions) !== null && _vm$formattedContextO !== void 0 && _vm$formattedContextO.length ? _c('simplebar', {
    staticClass: "gn-le-select__dropdown-inner",
    attrs: {
      "data-simplebar-aut-hide": "false"
    }
  }, _vm._l(_vm.formattedContextOptions, function (item) {
    var _item$data;

    return _c('div', {
      key: item === null || item === void 0 ? void 0 : item.value,
      class: ['gn-le-select__item', (item === null || item === void 0 ? void 0 : item.value) === _vm.activeUserContextId ? 'active' : ''],
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.handleInputEvent(item);
        }
      }
    }, [_c('GenproxDropdownItem', {
      attrs: {
        "image": (item === null || item === void 0 ? void 0 : (_item$data = item.data) === null || _item$data === void 0 ? void 0 : _item$data.logo) || null,
        "label": item === null || item === void 0 ? void 0 : item.label
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.highlightedText(item === null || item === void 0 ? void 0 : item.label, item === null || item === void 0 ? void 0 : item.data))
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 0) : _c('div', {
    staticClass: "gn-le-select__dropdown-inner no-options",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "gn-le-select__item"
  }, [_c('p', {
    staticClass: "gn-le-select__not-found"
  }, [_vm._v("No contexts found.")])])]), _vm.hasAfterlist ? _c('div', {
    staticClass: "gn-le-select__btn"
  }, [_c('sygni-rect-button', {
    staticClass: "filled gn-primary",
    on: {
      "click": _vm.addNewFundManagerHandle
    }
  }, [_c('PlusCircleIcon', {
    staticClass: "mr-2"
  }), _vm._v(" Add new fund ")], 1)], 1) : _vm._e()], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }